@font-face {
  font-family: GraphikRegular;
  src: url('assets/fonts/GraphikRegular.otf') format('opentype');
}

@font-face {
  font-family: GraphikMedium;
  src: url('assets/fonts/GraphikMedium.otf') format('opentype');
}

@font-face {
  font-family: RobotoRegular;
  src: url('assets/fonts/Roboto-Regular.ttf') format('truetype');
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: GraphikRegular, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  background: #181818;
}

/* Style overrides for select dropdown paper */
.MuiMenu-paper {
  background: #181818 !important;
  border-radius: 5px;
}

/* Style overrides for autocomplete dropdown */
.MuiAutocomplete-paper {
  background: #181818 !important;
  border-radius: 5px;
}

.MuiAutocomplete-option {
  font-family: GraphikRegular, sans-serif;
  font-size: 16px;
  margin: 0;
  color: #FFFFFF;
  letter-spacing: 0;
}

.MuiAutocomplete-option:hover,
.MuiAutocomplete-option.Mui-focused,
.MuiAutocomplete-option[aria-selected='true'] {
  background: #717171 !important;
}

.MuiAutocomplete-noOptions {
  font-family: GraphikRegular, sans-serif;
  font-size: 16px;
  margin: 0;
  color: #717171 !important;
  letter-spacing: 0;
}

/* Style overrides for tooltip */
#custom-tooltip .MuiTooltip-tooltip {
  max-width: 120px !important;
  text-align: center !important;
}

#custom-tooltip .MuiTooltip-tooltip {
  background: #FFFFFF !important;
  font-family: GraphikRegular, sans-serif !important;
  font-size: 16px !important;
  color: #717171 !important;
  padding: 10px;
}

#custom-tooltip .MuiTooltip-arrow {
  color: #FFFFFF !important;
}

/* Style overrides for date picker */
#dob-date-picker {
  background: #181818;
  color: #FFF;
}

#dob-date-picker .PrivatePickersFadeTransitionGroup-root {
  font-family: GraphikMedium, sans-serif;
}

#dob-date-picker .MuiButtonBase-root svg {
  color: #FFFFFF;
}

#dob-date-picker .PrivatePickersYear-yearButton,
#dob-date-picker .PrivatePickersMonth-root,
#dob-date-picker .MuiPickersDay-root {
  font-family: GraphikRegular, sans-serif;
  background: transparent;
  color: #FFFFFF;
}

#dob-date-picker .PrivatePickersYear-yearButton.Mui-selected,
#dob-date-picker .PrivatePickersMonth-root.Mui-selected,
#dob-date-picker .MuiPickersDay-root.Mui-selected {
  background: transparent;
  border: 1px solid #F98F43;
  color: #FFFFFF;
}

#dob-date-picker .PrivatePickersYear-yearButton.Mui-disabled {
  display: none;
}

#dob-date-picker .MuiTypography-caption {
  color: #FFFFFF;
  font-family: GraphikMedium, sans-serif;
}

#dob-date-picker .MuiPickersDay-root.Mui-disabled {
  color: #999999;
}

/* Print styles */
@media print {
  /* Ensures all background colors are printed */
  body {
    -webkit-print-color-adjust: exact;
  }
}
